<template>
	<v-data-table
		:loading="loading"
		:headers="headers"
		:items="i18nPhrasesTranslatesList.items"
		hide-default-header
		:options.sync="options"
		:server-items-length="i18nPhrasesTranslatesList.total"
		:calculate-widths="true"
		class="elevation-1"
		:footer-props="{
			itemsPerPageOptions: [5,  15, 25, 50, 100],
			itemsPerPageText: 'Строк на стр.'
		}"
		loading-text="Загрузка данных"
	>
		<template v-slot:header :headers="headers" >
			<thead class="v-data-table-header">
				<tr>
					<th v-for="header in headers"
						:key="header.value"
						role="columnheader"
						scope="col"
						class="text-start"
						:class="{'v-data-table__divider': header.divider}"
						>
						<div class="align-center d-flex">
							<span>{{ header.text }}</span>
							<v-text-field
								v-if="header.search"
								variant="outlined"
								prepend-inner-icon="mdi-magnify"
								class="mt-0 ml-5"
								clearable
								v-model.lazy="getSearchInput(header.langId).value"
								@change="search($event, getSearchInput(header.langId).id)"
							>
							</v-text-field>
						</div>
					</th>
				</tr>
			</thead>
        </template>
		<template v-slot:item.name="props">
			<v-label-editable
				v-model="props.item.name"
				:rules="[rules.required]"
				@input="saveEntity(props)"
			/>
		</template>
		<template v-slot:item.translate.ru-RU.value="props">
			<v-translation-editable
				v-model="props.item.translate['ru-RU'].value"
				@input="save(props, 'ru-RU')"
			/>
		</template>
		<template v-slot:item.translate.en-US.value="props">
			<v-translation-editable
				v-model="props.item.translate['en-US'].value"
				@input="save(props, 'en-US')"
			/>
		</template>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Переводы лейблов</v-toolbar-title>
				<v-spacer/>
				<v-col cols="6">
					<v-autocomplete
						outlined
						hide-details
						clearable
						dense
						no-data-text="Нет данных"
						:items="i18nPhrasesPlaces.items"
						item-text="name"
						:menu-props="{ top: false, offsetY: true }"
						label="Фильтр по местоположению"
						@change="updateFilters('place', $event)"
						v-model="filters.place"
					/>
				</v-col>
			</v-toolbar>
			<v-divider/>
		</template>
		<template v-slot:no-data>
			<v-btn
				color="primary"
				@click="reset"
			>
				Сбросить
			</v-btn>
		</template>
	</v-data-table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import labelEditable from '@/components/labelEditable'
import translationEditable from '../components/translationEditable'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	components: {
		'v-label-editable': labelEditable,
		'v-translation-editable': translationEditable
	},

	data: function () {
		return {
			title: 'Переводы лейблов',
			options: {},
			loading: true,
			place: '',

			searchInputs: [
				{
					id: 1,
					value: null
				},
				{
					id: 2,
					value: null
				}
			],

			headers: [
				{
					text: 'ID',
					sortable: false,
					value: 'id',
					divider: true
				},
				{
					text: 'Имя',
					sortable: false,
					value: 'name',
					divider: true,
					width: 250
				},
				{
					text: 'Место',
					sortable: false,
					value: 'place',
					divider: true
				},
				{
					text: 'Перевод RU',
					sortable: false,
					value: 'translate.ru-RU.value',
					divider: true,
					search: true,
					langId: 1
				},
				{
					text: 'Перевод EN',
					sortable: false,
					value: 'translate.en-US.value',
					search: true,
					langId: 2
				}
			],

			filters: {
				place: ''
			},

			rules: {
				required: value => !!value || 'Поле обязательно'
			}
		}
	},

	computed: {
		...mapGetters({
			i18nPhrasesTranslatesList: 'i18n/i18nPhrasesTranslatesList',
			i18nPhrasesPlaces: 'i18n/i18nPhrasesPlaces'
		}),

		formTitle () {
			return 'Редактирование перевода'
		},

		/* Фильтры */
		urlFilters () {
			/* Инициализация фильтров */
			const urlFilters = this.$route.query
			const filters = {}

			filters.page = +urlFilters.page || 1
			filters.limit = +urlFilters.limit || 15
			filters.lang = urlFilters.lang
			filters.text = urlFilters.text

			if (urlFilters.place) {
				filters.place = urlFilters.place
			}

			return filters
		}
	},

	watch: {
		/* Отслеживание изменения фильтров */
		'$route' (to, from) {
			this.initialize()
		},

		/* Отслеживание изменения состояния таблицы */
		options (to) {
			const filters = {}
			if (this.urlFilters.page !== to.page) {
				filters.page = to.page
			}

			if (this.urlFilters.limit !== to.itemsPerPage) {
				filters.limit = to.itemsPerPage
			}

			if (filters.page || filters.limit) {
				this.updateUrl(filters)
			}
		}
	},

	created () {
		/* Инициализация фильтров */
		this.$set(this, 'filters', this.urlFilters)

		if (this.urlFilters.lang && this.urlFilters.text) {
			const langIdx = this.searchInputs.findIndex((input) => +input.id === +this.urlFilters.lang)
			if (langIdx !== -1) {
				this.$set(this.searchInputs[langIdx], 'value', this.urlFilters.text)
			}
		}

		this.initialize()
		this.initPlaceFilter()
	},

	methods: {
		...mapActions({
			getPhrasesTranslatesList: 'i18n/getPhrasesTranslatesList',
			getPlaces: 'i18n/getPlaces',
			putPhrasesTranslatesList: 'i18n/putPhrasesTranslatesList',
			postPhrasesTranslatesList: 'i18n/postPhrasesTranslatesList',
			deletePhrasesTranslatesList: 'i18n/deletePhrasesTranslatesList',
			putPhrasesList: 'i18n/putPhrasesList'
		}),
		async initialize () {
			this.loading = true

			this.$set(this, 'options', {
				...this.options,
				itemsPerPage: this.urlFilters.limit,
				page: this.urlFilters.page
			})

			try {
				await this.getDataFromApi()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}

			this.loading = false
		},
		async initPlaceFilter () {
			this.loading = true

			try {
				await this.getPlaces({ type: 'label' })
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
		},

		async getDataFromApi () {
			this.loading = true

			await this.getPhrasesTranslatesList({
				limit: this.urlFilters.limit,
				offset: (this.urlFilters.page - 1) * this.urlFilters.limit,
				type: 'label',
				place: this.urlFilters.place,
				value: this.urlFilters.text
			}
			).then(
				() => {
				},
				(error) => {
					this.$notify({
						type: 'error',
						title: error
					})
				}
			)

			this.loading = false
		},
		save (props, lang) {
			const payload = props.item.translate[`${lang}`]
			payload.phrase_id = props.item.id
			try {
				this[payload.id ? 'putPhrasesTranslatesList' : 'postPhrasesTranslatesList'](
					payload
				)
					.then(
						() => {
							this.$notify({ type: 'info', title: payload.id ? 'Перевод изменён' : 'Перевод добавлен' })
							this.getDataFromApi()
							this.initPlaceFilter()
						}, (error) => {
							this.$notify({
								type: 'error',
								title: error
							})
						}
					)
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
		},
		saveEntity (props) {
			const payload = {
				id: props.item.id,
				name: props.item.name,
				place: props.item.place,
				type: 'label'
			}
			try {
				this.putPhrasesList(
					payload
				).then(
					() => {
						this.$notify({ type: 'info', title: 'Имя лейбла изменено' })
						this.getDataFromApi()
						this.initPlaceFilter()
					}, (error) => {
						this.$notify({
							type: 'error',
							title: error
						})
					}
				)
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
		},

		updateFilters (filter, value) {
			const filters = {
				[filter]: value ? String(value) : undefined
			}

			const queryParams = this.$route.query

			switch (true) {
			case filter === 'place' && queryParams[filter] !== value && queryParams[filter] !== null && value !== null:
			case filter === 'limit':
			case filter === 'lang':
			case filter === 'text':
				filters.page = undefined
				break
			}

			this.updateUrl(filters)
		},

		/* Обновление URL */
		async updateUrl (query) {
			try {
				await this.$router.push({
					query: {
						...this.$route.query,
						...query
					}
				})
			} catch (error) {}
		},

		search (value, id) {
			if (!id) { return }

			const inputToClean = this.searchInputs.find((input) => input.id !== id)
			this.cleanInput(inputToClean)

			if (!value) {
				this.updateFilters('lang', undefined)
				this.updateFilters('text', undefined)
				return
			}

			this.updateFilters('lang', id)
			this.updateFilters('text', value)
		},

		getSearchInput (langId) {
			return this.searchInputs.find((input) => input.id === langId)
		},

		cleanInput (input) {
			if (input) {
				input.value = null
			}
		},

		reset () {
			Object.keys(this.urlFilters).forEach(key => {
				switch (key) {
				case 'page':
					this.updateFilters(key, 1)
					break
				case 'limit':
					this.updateFilters(key, 15)
					break
				default:
					this.updateFilters(key, undefined)
				}
			})
			this.searchInputs.forEach(input => this.cleanInput(input))

			this.initialize()
		}
	}
}
</script>
